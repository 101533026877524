body {
    background-color: #ccc;
    font-family: sans-serif;
    margin: 0;
}

.home-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .home-card {
        margin-top: 10vh;

        h1 {
            margin: 20px 0;
            font-size: 3rem;
            text-align: center;
            color: #666;
        }

        .home-form {
            padding: 30px;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
            background-color: #fff;

            input {
                margin-right: 10px;
                font-size: 1.1rem;
            }

            button {
                font-size: 1.1rem;
            }
        }
    }

}

.pre-game-wrapper {
    display: flex;
    justify-content: center;
}

.pre-game-main {
    display: flex;
    justify-content: center;
    max-width: 1300px;
    width: 90vw;

    .game-lobby {
        padding: 10px;
        margin: 10px;
        flex-grow: 1;
        background-color: #eee;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);

        h2 {
            font-family: sans-serif;
            margin-bottom: 5px;
        }

        label {
            display: block;
        }

        form {
            div {
                margin-bottom: 20px;
            }

            input {
                font-size: 1rem;
            }

            span {
                color: #666;
                font-size: 0.9rem;
            }
        }

        .pre-game-players {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            .player-card {
                margin: 10px;
            }
        }

        .start-button-wrapper {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            button {
                cursor: pointer;
                background-color: #157f15;
                color: white;
                border: none;
                border-radius: 4px;
                padding: 10px;
                font-size: 1.1rem;
                outline: none;
            }

            button:disabled,button:disabled:hover {
                cursor: initial;
                background-color: #72b372;
            }

            button:hover {
                background-color: #2b922b;
            }
        }
    }
}

.game-main {
    display: flex;
    justify-content: center;

    .game-players {
        width: 85px;
        margin: 40px 5px 5px 5px; /* TODO: This margin is coupled with game-state height*/
        display: flex;
        flex-direction: column;
    }

    .game-right {
        .game-canvas-chat {
            display: flex;

            .game {
                margin: 5px;

                .game-state {
                    height: 30px;
                    margin-bottom: 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .game-round {
                        margin: 0 5px;
                        font-family: sans-serif;
                        font-size: 1.3rem;
                        color: #444;
                    }

                    .game-timer {
                        margin: 0 5px;
                        font-family: sans-serif;

                        .timer-amount {
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: #444;
                        }

                        .timer-units {
                            font-size: 1rem;
                            color: #666;
                        }
                    }

                    .game-word {
                        margin: 0 5px;
                        letter-spacing: 4px;
                        font-family: monospace;
                        font-size: 1.3rem;
                        font-weight: bold;
                        color: #444;
                    }
                }

                canvas {
                    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
                    background-color: #eee;
                }
            }
        }

        .controls {
            padding: 5px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .size-controls {
                margin-right: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;

                button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    cursor: pointer;
                    display: block;
                    background-color: transparent;
                    border: none;
                    padding: 0 10px;
                    outline: none;

                    div {
                        border-radius: 50%;
                        margin: 0 auto;
                        border: 2px solid white;
                    }

                    div.selected {
                        box-shadow: rgba(0, 0, 0, 0.6) 0 4px 8px;
                    }
                }
            }

            .color-controls {
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                .color-button {
                    cursor: pointer;
                    display: block;
                    width: 30px;
                    height: 30px;
                    margin: 3px;
                    border: 2px solid white;
                    outline: none;
                }

                .selected {
                    box-shadow: rgba(0, 0, 0, 0.6) 0 4px 8px;
                }
            }

            .other-controls {
                margin-left: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;

                button {
                    cursor: pointer;
                    border: none;
                    background: none;
                    font-size: 30px;
                    color: #333;
                }

                button:hover {
                    color: #555;
                }
            }
        }
    }
}

.right-container {
    position: relative;
    width: 15vw;
    min-width: 400px;
    max-width: 500px;
    margin: 10px;

    .chat-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .chat {
            flex-grow: 1;
            display: flex;
            flex-direction: column-reverse;
            overflow-y: auto;

            .chat-row {
                font-family: sans-serif;
                padding: 4px 8px;

                .chat-row-name {
                    font-weight: bold;
                }

                .chat-row-msg {
                    color: #333;
                }
            }

            .chat-row-system {
                font-weight: bold;
            }
        }

        form {
            margin-top: 8px;
            display: flex;
            flex-direction: row;

            input {
                margin-right: 5px;
                background-color: #dedede;
                border: none;
                border-radius: 4px;
                padding: 8px;
                font-size: 1rem;
                color: #555;
                flex-grow: 1;
                outline: none;
            }

            input.winner {
                background-color: #95ca88;
            }

            input::placeholder {
                color: #777;
            }

            input.winner::placeholder {
                color: #444;
            }

            input:focus {
                background-color: #eee;
            }

            input.winner:focus {
                background-color: #95ca88;
            }

            button {
                border: none;
                border-radius: 4px;
                background-color: #5370c3;
                color: white;
                cursor: pointer;
            }
        }
    }
}

.player-card {
    display: flex;
    position: relative;
    width: 85px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

    .player-name {
        width: 100%;
        text-align: center;
        background-color: white;
        font-family: sans-serif;
        font-weight: bold;
        font-size: 0.8rem;
        color: #454545;
    }

    .player-score {
        width: 100%;
        text-align: center;
        background-color: white;
        font-family: sans-serif;
        font-size: 0.8rem;
        color: #454545;
    }

    i {
        position: absolute;
        background-color: white;
        border-radius: 20%;
    }

    i.icon-pencil {
        top: 3px;
        left: 3px;
        padding: 1px 0;
        line-height: 16px;
    }

    i.icon-crown {
        top: 3px;
        right: 3px;
        padding-top: 2px;
        line-height: 16px;
    }
}

.player-card.player-winner {
    .player-name {
        background-color: lightgreen;
    }
    .player-score {
        background-color: lightgreen;
    }
}

.player-card.player-disconnected {
    opacity: 0.5;
}

.modal-container {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modal {
    display: flex;
    position: inherit;
    margin-top: 20vh;
    background-color: transparent;
}

.other-word-selection {
    font-weight: bold;
    font-size: 2.5rem;
    color: white;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.word-options {
    button {
        background-color: lightgreen;
        color: #666;
        border: 1px solid lightgreen;
        border-radius: 4px;
        font-size: 2.5rem;
        margin: 0 15px;
        padding: 5px 15px;
        cursor: pointer;
    }

    button:hover {
        background-color: #666;
        color: lightgreen;
    }
}

.player-list-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .room-link {
        display: flex;
        align-items: center;

        input {
            margin: 5px;
            width: 36ch;
            flex-grow: 1;
        }
    }
}

.standings {
    background-color: #eee;
    width: 90vw;
    max-width: 400px;
    padding: 10px;
    font-size: 1.4rem;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);

    h2 {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .standing-player {
        display: flex;
        justify-content: space-between;

        .standing-place {
            width: 60px;
            font-weight: bold;
        }

        .standing-name {
            flex-grow: 1;
            color: #444;
        }

        .standing-score {
            margin-left: 20px;
            text-align: left;
            font-weight: bold;
            color: #119911;
        }

        .standing-points {
            margin-left: 5px;
        }
    }

    .standing-player.self {
        background-color: lightblue;
    }
}
